<template>
  <div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    />
    <v-layout align-center>
      <div class="font-weight-light"
           :class="{'headline': $vuetify.breakpoint.mdAndUp, 'body-1': $vuetify.breakpoint.smAndDown}"
      >
        <span class="subhead">E</span>xplore our library of meticulously crafted lessons on the subject of electric circuits.
        <br>
        <br>
      </div>
    </v-layout>
    <v-container grid-list-xl text-xs-center>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex v-for="topic in topics"
                :key="topic.key"
                xs12
                sm12
                md12
                lg12
                xl12
        >
          <subtopic-card
            :headline="topic.headline"
            :description="topic.description"
            :img="topic.img"
            :path="topic.path"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import SubtopicCard from '../../../views/SubTopics.vue'
export default {
    name: 'Subtopics',
    components: {
    SubtopicCard
    },
    data () {
    return {
      topics: [
      {
        headline: "Ohm's Law", description: "Ohm's law is a fundamental principle that relates the voltage applied across a conducting material to the electric current flowing through it.  ",
        img:"/assets/wdice-1.svg", path: "/physics/electricity/ol"
      },
      { headline: "Capacitors", description: "Kirchhoff’s voltage law can be used to calculate voltage drops across different components of an electric circuit, simple or complex.",
        img: "/assets/wdice-2.svg", path: "/physics/electricity/capacitors"
      },
      { headline: "Resistors", description: "A resistor is an Ohmic device that is used to limit the flow of charge in a circuit",
          img: "/assets/wdice-3.svg", path: "/physics/electricity/resistors"
      },
      {
        headline: "RC Circuits", description: "Most basic type of electric circuit in which a resistor and a capacitor are connected in series. ",
          img: "/assets/wdice-4.svg", path: "/physics/electricity/rc"
      }
      ]
    }
  },
  created () {
    this.$store.commit('navigation/resetState');
    this.$store.commit('navigation/changeTitle', 'Electric Circuits');
    this.$store.commit('navigation/changeMenu', 'Electric Circuits');
    let newTopics = [
      {title: 'Ohm\'s Law', img:'/assets/number-1.svg', action: () => this.$router.push({path: '/physics/electricity/ol'})},
      {title: 'Kirchhoff\'s Voltage Law', img:'/assets/number-2.svg', action: () => this.$router.push({path: '/physics/electricity/ol'})},
      {title: 'Resistors',img:'/assets/number-3.svg', action: () => this.$router.push({path: '/physics/electricity/resistors'})},
      {title: 'RC Circuits', img:'/assets/number-4.svg', action: () => this.$router.push({path: '/physics/electricity/rc'})},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  metaInfo() {
    return{ title: 'Electric Circuits',
            titleTemplate: '%s - Learn interactively',
            meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                    {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                  ]
          }
     },
}
</script>
<style scoped lang="scss">
.subhead {
color: #903;
float: left;
font-family: Georgia;
font-size: 75px;
line-height: 60px;
padding-top: 4px;
padding-right: 8px;}
</style>
